<template lang="pug">
div.pt-5
  v-container(fluid px-4).title.black.white--text
    v-btn(@click='$router.go(-1)' fab small icon).white.mr-3
      v-icon arrow_back
    template {{design.name}} - {{item.dashboard.jobSubtitle}} -
      span.font-weight-bold {{item.location.template.name}}
    span(v-if='job.removeProductCost').title.pink--text.text--accent-2.font-weight-bold.ml-3 CONTRACT PRINT
  FwdTabs(
    v-model="tabs"
    :items="tabItems"
  )
  v-dialog(max-width=600 v-model="actions")
    FwdCard
      template(slot="title") actions
      template(slot="content")
        Actions
</template>

<script>
import { mapFields } from 'vuex-map-fields'
import Mockup from '@/modules/designs/components/document/index-production'
import Images from '@/modules/designs/components/images'
import Notes from './notes'
import LineItems from './product-groups'
import Controls from './controls'
import Actions from './actions'
// import DetailForm from '../form'

export default {
  components: {
    Controls,
    Actions
  },
  data: () => ({
    tabItems: [
      { title: 'line items', component: LineItems },
      { title: 'mockup', component: Mockup },
      { title: 'notes', component: Notes },
      { title: 'images', component: Images }
    ]
  }),
  computed: {
    ...mapFields('productionInstances', {
      item: 'item',
      items: 'items',
      loaded: 'loaded'
    }),
    ...mapFields('dashboards', {
      actions: 'actions',
      tabs: 'tabs'
    }),
    design () {
      return this.$store.state.designs.item
    },
    job () {
      return this.$store.state.jobs.item
    }
  },
  async created () {
    // load production instance
    await this.$store.dispatch(
      'productionInstances/_READ_PRODUCTION',
      this.$route.params.id
    )
    // // load design and job
    // // await this.$store.dispatch('designs/_LOAD', this.item.design)
    // await this.$store.dispatch('jobs/_LOAD', this.item.job)
    // // make sure we have the right production instance highlighted
    // this.$store.commit('productionInstances/item', this.items.find(x => x._id === this.$route.params.id))
  }
  // beforeRouteLeave (to, from, next) {
  //   this.$store.dispatch('jobs/setup')
  //   this.$store.dispatch('jobs/_SAVE').then(x => {
  //     this.$store.dispatch('jobs/cleanup')
  //     next()
  //   })
  // }
}
</script>
