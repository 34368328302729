<template lang="pug">
div
  SampleTicket
  InkTicket
  ScreenTicket
</template>

<script>
import SampleTicket from './SampleTicket'
import InkTicket from './InkTicket'
import ScreenTicket from './ScreenTicket'
export default {
  components: {
    SampleTicket,
    InkTicket,
    ScreenTicket
  }
}
</script>
