import sortFilter from '@/mixins/sort-filter'
const sf = sortFilter.methods

export default (payload) => {

  // unpack
  const { items } = payload

  // remove zero quantity line items
  const lineItemsWithoutZeros = items.filter(item => item.quantity > 0)

  // sort list by name
  // return sf.sortByPropNumber(lineItemsWithoutZeros, 'sizeSortOrder')

  return lineItemsWithoutZeros
}