<template lang="pug">
div(v-if="loaded")
  ListSchedule(
    :items="productionInstances"
    @select="select"
  )
    template(slot="line1" slot-scope="{ item }")
      v-layout(row)
        v-flex(xs12) {{item.dashboard.designName}} - {{item.dashboard.jobSubtitle}} -
          span(v-if='item.location.template').font-weight-bold {{item.location.template.name}}

    template(slot="line2" slot-scope="{ item }")
      v-layout(row)
        v-flex(xs5)
          PiSubtitle(:item='item')
</template>

<script>
import ListSchedule from './list'
import PiSubtitle from '../../../components/pi-subtitle'

export default {
  components: { ListSchedule, PiSubtitle },
  data: () => ({ progressToggle: false }),
  computed: {
    productionInstances () {
      return this.$store.getters['productionInstances/items']
    },
    loaded () {
      return this.$store.state.productionInstances.loaded
    }
  },
  methods: {
    select (item) {
      // bring up a PI detail
      this.$router.push({ path: '/dashboards/production/' + item._id })
    }

  },
  async created () {
    await this.$store.dispatch('dashboards/_LOAD_PRODUCTION')
  }
}
</script>
