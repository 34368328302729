<template lang="pug">
div
  div(v-if='!approved').title.purple--text.pa-3.pink.lighten-5 Please approve the mockup before proceeding with production.
  v-container(fluid px-4).display-1.font-weight-bold.pink--text 1) pick a product
  CList
  v-divider.mb-3
  v-container(fluid px-4).display-1.font-weight-bold.pink--text 2) input quantities
  CDetail
  v-divider.mb-3
  v-container(fluid px-4).display-1.font-weight-bold.pink--text 3) finish up
  CCompleted
</template>

<script>
import CList from "./list"
import CDetail from "./detail"
import CCompleted from "./completed"
import { mapFields } from 'vuex-map-fields'

export default {
  components: { CList, CDetail, CCompleted },
  computed: {
    ...mapFields('productionInstances', {
      approved: 'item.approval.approved',
    })
    
  }
};
</script>
