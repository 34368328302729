<template lang="pug">
div
  v-layout(v-if="productGroups.length" row)
    v-flex(xs3)
      v-layout(row pl-5 py-2).body-2.pink.lighten-5
        v-flex.title {{product.code}}
      div.caption.pl-5.mt-4 {{product.brand.name}}
      div.pl-5 {{product.name}}
      div
        v-img(height="250px" contain :src="product.image.url")
    v-flex(xs9)
      LineItems(
        :lines="itemsSorted"
      )
</template>

<script>
import { listSortLines } from '../helpers'
import { LineItem } from '@/models'
import { mapFields } from 'vuex-map-fields'
import LineItems from '@/modules/product-groups/components/line-items'

export default {
  components: { LineItems },
  computed: {
    ...mapFields('productGroups',{
      productGroup: 'item',
      productGroups: 'items',
      product: 'item.product',
      lineItems: 'item.lineItems',
    }),
    itemsSorted () { return listSortLines({ items: this.lineItems }) }
  },
  beforeMount () {
    const settings = {
      quantity: { show: true, input: false },
      quantityOrdered: { show: true, input: false },
      quantityReceived: { show: true, input: false },
      quantityFinal: { show: true, input: true },
      cost: { show: false },
      costExtend: { show: false },
    }
    this.$store.dispatch('productGroups/settings', settings)
  }
}
</script>