<template lang="pug">
v-container(fluid).px-4
  v-layout(row)
    v-flex(xs4)
      v-btn(color="pink accent-2" :disabled='disabled' @click='markCompleted').white--text mark location completed
    v-flex(xs4)
      v-btn(color="pink accent-2" :disabled='!disabled' @click='markIncomplete').white--text mark location incomplete
  v-layout(row)
    v-flex(xs4 pl-3) click this if you have completed printing for this location
    v-flex(xs4 pl-3) click this if you need to move an item back to the production dashboard

</template>

<script>
export default {
  props: ["pi_id"],
  computed: {
    pi() {
      return this.$store.getters["productionInstances/item"];
    },
    disabled() {
      return this.pi.status === "completed";
    },
  },
  methods: {
    markCompleted() {
      this.$store.dispatch("productionInstances/markCompleted");
    },
    markIncomplete() {
      this.$store.dispatch("productionInstances/markIncomplete");
    },
    markFinished() {
      this.$store.dispatch("productionInstances/markFinished", {
        pi: this.pi,
        name: this.pi.dashboard.designName,
      });
    },
  },
};
</script>
