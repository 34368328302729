import router from '@/router'

const items = [
  { title: 'sample ticket', action: sampleTicket },
  { title: 'screen ticket', action: screenTicket },
  { title: 'ink ticket', action: inkTicket }
]

function sampleTicket (param) {
  const id = param.job
  const routeData = router.resolve('/jobs/sample-ticket/print/' + id)
  window.open(routeData.href, '_blank')
}

function screenTicket (param) {
  const id = param._id
  const routeData = router.resolve('/jobs/screen-ticket/print/' + id)
  window.open(routeData.href, '_blank')
}

function inkTicket (param) {
  const id = param._id
  const routeData = router.resolve('/jobs/ink-ticket/print/' + id)
  window.open(routeData.href, '_blank')
}

export default items
