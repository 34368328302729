<template lang="pug">
div
  v-toolbar(flat fixed color='white')
    v-btn(
      @click='toggleDrawer'
      small
      flat
      icon
      )
      v-icon() menu
    v-toolbar-title.display-1.font-weight-bold {{ title }}
    v-spacer
    OoMenu(:items='menu' :param='param' v-if='param')

</template>
<script>
import OoMenu from "./menu";
export default {
  components: { OoMenu },
  props: {
    title: { type: String },
    menu: { type: Array },
    param: { type: String },
  },
  methods: {
    toggleDrawer() {
      this.$store.dispatch("core/toggleDrawer");
    },
  },
};
</script>
