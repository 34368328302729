<template lang="pug">
div.pt-5
  PublicNotes
  ProductionNotes
</template>

<script>
import PublicNotes from '@/modules/designs/components/notes/notes-public'
import ProductionNotes from '@/modules/designs/components/notes/notes-production'

export default {
  components: {
    PublicNotes,
    ProductionNotes
  },
}
</script>
