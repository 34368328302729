<template lang="pug">
div
  v-menu(left)
    template(v-slot:activator="{ on }")
      v-btn(
        icon
        v-on="on"
      )
        v-icon more_vert

    v-list
      v-list-tile(
        v-for="(item, i) in items"
        :key="i"
        @click='item.action(param)'
      )
        v-list-tile-title {{ item.title }}
</template>
<script>
export default {
  props: {
    items: { type: Array },
    param: { type: Object }
  }
}
</script>
