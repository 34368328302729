<template lang="pug">
div
  OoToolbar(
    title='production'
    :menu='menu'
    :param='item'
  ).mb-5
  v-container(fluid).mt-5.pa-0
    OoDetail(v-if='$route.params.id')
    OoList(v-else)
</template>

<script>
import OoList from './list'
import OoDetail from './detail'
import OoToolbar from './ toolbar'
import menu from './config/menu'

export default {
  components: { OoList, OoDetail, OoToolbar },
  computed: {
    item () {
      return this.$store.getters['productionInstances/item']
    },
    menu: () => menu
  }
}
</script>
