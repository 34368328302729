<template lang="pug">
div
  ListSelected(
    :items="items"
    :toolbar="false"
    :selected="item"
    @select="select"
  )
    template(slot-scope="{ item }")
      v-layout.body-1.pa-0(row) 
        v-flex(xs8) {{item.product.displayName}}
        v-flex(xs2) {{item.quantityFinal}} / {{item.quantity}}
        v-flex(xs2) {{item.subtotalFinal | currency}}
        
</template>

<script>
import { listSort } from '../helpers'
import { mapFields } from 'vuex-map-fields'
import ListSelected from '@/components/base/list-selected-single-line'

export default {
  components: { ListSelected },
  computed: {
    ...mapFields('productGroups', ['item', 'items']),
    itemsSorted () { return listSort({ items: this.items }) }
  },
  methods: {
    async select (item) { await this.$store.dispatch('productGroups/_SELECT', item) },
  }
}
</script>
