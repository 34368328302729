<template lang="pug">
div()
  v-list(subheader two-line)
    div(
      v-for='(item, i) in itemsSorted'
      :key='i'
    )
      v-list-tile(fluid v-if='item.task.isTask').teal.lighten-5
        v-list-tile-content
          v-list-tile-title.title.font-weight-bold
            span(v-if='item.task.isComplete').pa-2.teal.white--text realizado!
            span  task --> {{ item.task.assignee }}
          v-list-tile-sub-title.subheading {{ item.task.assignment }}
        v-list-tile-action
          v-checkbox(@change='taskToggle(item)' v-model='item.task.isComplete' label='done?' )

      v-list-tile(
        v-if="item.schedType === 'line' && !item.task.isTask"
        :class="tileClass(item)"
        avatar
      ).list-item
        v-list-tile-avatar(v-if="item.dashboard.image.url" tile @click="select(item)")
          img(:src="item.dashboard.image.url")
        v-list-tile-content(@click="select(item)")
          v-list-tile-title
            slot(name="line1" :item="item")
          v-list-tile-sub-title
            slot(name="line2" :item="item")

      v-list-tile.list-item.black(
        v-if="item.schedType === 'date'"
      )
        v-list-tile-content.white--text
          v-list-tile-title.font-weight-black.title
            | {{ item._tempDate }}

      v-list-tile.list-item.orange.lighten-4(
        v-if="item.schedType === 'team'"
      )
        span.font-weight-black.display-2 {{ item.team.name }}

</template>
<script>
import scheduleSort from "../../../helpers/sort";

export default {
  props: {
    items: { type: Array },
  },
  computed: {
    itemsSorted() {
      const items = scheduleSort(this.items);
      return items;
    },
  },
  methods: {
    select(item) {
      this.$emit("select", item);
    },

    tileClass(item) {
      if (item.dashboard.isPressCheck) {
        return "highlight-presscheck";
      }
      const ddDate = item.dates.dropDeadOverride
        ? item.dates.dropDeadOverride
        : item.dates.dropDead;
      const isDateWarning = ddDate ? ddDate < item.dates.schedule : false;
      if (isDateWarning) {
        return "highlight-warning";
      }
    },
    taskToggle(item) {
      // const pis = this.$store.getters['productionInstances/items']
      // const pi = pis.find(x => x._id === item._id)
      // const instance = new ProductionInstance(pi)
      // console.log(instance.task.isComplete)
      // instance.task.isComplete = !instance.task.isComplete
      // console.log(instance.task.isComplete)
      this.$store.commit("productionInstances/item", item);
      this.$store.dispatch("productionInstances/_UPDATE");
      this.$store.commit("productionInstances/itemsUpdate", {
        list: "items",
        item: item,
      });
      // console.log(instance)
    },
  },
};
</script>

<style lang="stylus" scoped>
.highlight-warning
  background-color #FFCDD2
.highlight-presscheck
  background-color #C8E6C9
</style>
